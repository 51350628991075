import React, { useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faPen,
  faPalette,
  faMale,
  faEnvelope
} from "@fortawesome/free-solid-svg-icons";

import Social from "../header/social";

import { COLORS } from "../../constants";

const menuItems = [
  {
    icon: faPen,
    link: "/",
    title: "BLOG"
  },
  {
    icon: faPalette,
    link: "/illustrations",
    title: "ILUSTRATIONS"
  },
  {
    icon: faMale,
    link: "/about",
    title: "ABOUT"
  },
  {
    icon: faEnvelope,
    link: "/contact",
    title: "CONTACT"
  }
];

const NavigationWrapper = styled.div`
  display: flex;
  flex: 9;
  padding: 0;
  justify-content: flex-end;
`;

const MenuWrapper = styled.div`
  flex: 1;
  background: ${COLORS.BLACK.DARK};
  color: ${COLORS.WHITE};
  justify-content: flex-start;
`;

const styles = () => ({
  textLabel: {
    color: COLORS.WHITE,
    fontWeight: "bold"
  }
});

const baseStyles = {
  links: {
    textDecoration: "none"
  }
};

const MenuMobile = props => {
  const { classes } = props;
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <NavigationWrapper>
      <FontAwesomeIcon
        icon={faBars}
        size="2x"
        color={COLORS.WHITE}
        onClick={() => setMenuOpen(true)}
      />
      <Drawer anchor="right" open={menuOpen} onClose={() => setMenuOpen(false)}>
        <MenuWrapper
          role="presentation"
          onClick={() => setMenuOpen(false)}
          onKeyDown={() => setMenuOpen(false)}
        >
          <List component="nav">
            {menuItems.map(item => (
              <Link
                to={item.link}
                key={`link-${item.title}`}
                style={baseStyles.links}
              >
                <ListItem button divider>
                  <ListItemIcon>
                    <FontAwesomeIcon
                      icon={item.icon}
                      size="lg"
                      color={COLORS.WHITE}
                      onClick={() => setMenuOpen(true)}
                      fixedWidth
                    />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={item.title}
                    className={classes.textLabel}
                  />
                </ListItem>
              </Link>
            ))}
          </List>
          <Social mode="mobile" />
        </MenuWrapper>
      </Drawer>
    </NavigationWrapper>
  );
};

export default withStyles(styles)(MenuMobile);
