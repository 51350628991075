import React, { Component } from "react";
import { Link } from "gatsby";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Avatar from "@material-ui/core/Avatar";
import { withStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareSquare } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import styled from "styled-components";

import ShareWidget from "./share-widget";
import Tag from "./tag";

import { COLORS } from "../../constants";

import xabaduAnimated from "../../images/icon.png";

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 0.7rem;
  align-items: center;
`;

const IconWrapper = styled.span`
  margin-right: 0.3rem;
`;

const TitleText = styled.h3`
  font-size: 1.3rem;
  @media (max-width: 320px) {
    font-size: 1.2rem;
  }
`;

const styles = theme => ({
  postCard: {
    border: `1px solid ${COLORS.GRAY.LIGHT}`,
    fontFamily: theme.typography.fontFamily
  },
  postTitle: {
    fontFamily: theme.typography.fontFamily,
    height: "3.5rem"
  },
  postLink: {
    color: COLORS.GRAY.DARK,
    textDecoration: "none"
  }
});

class PostCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClick(event) {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose() {
    this.setState({ anchorEl: null });
  }

  render() {
    const { classes, date, image, link, tags, timeToRead, title } = this.props;
    const { anchorEl } = this.state;

    return (
      <Card className={classes.postCard}>
        <Link to={link}>
          <CardMedia
            image={image.src}
            title="Article picture"
            style={{ paddingTop: "56.25%" }}
          />
        </Link>
        <CardHeader
          avatar={<Avatar aria-label="Recipe" src={xabaduAnimated} />}
          action={
            <div>
              <IconButton onClick={this.handleClick}>
                <FontAwesomeIcon
                  icon={faShareSquare}
                  size="xs"
                  color={COLORS.GRAY.DARK}
                />
              </IconButton>
              <ShareWidget
                anchorEl={anchorEl}
                handleClose={this.handleClose}
                title={title}
                slug={link}
              />
            </div>
          }
          title={
            <TitleWrapper>
              <p>{date}</p>
            </TitleWrapper>
          }
          subheader={
            <TitleWrapper>
              <IconWrapper>
                <FontAwesomeIcon
                  icon={faClock}
                  size="1x"
                  color={COLORS.GRAY.DARK}
                />
              </IconWrapper>
              {`${timeToRead} min`}
            </TitleWrapper>
          }
        />
        <Divider variant="middle" />
        <CardContent>
          <Link className={classes.postLink} to={link}>
            <TitleText className={classes.postTitle}>{title}</TitleText>
          </Link>
        </CardContent>
        <CardActions disableActionSpacing>
          {tags.map(tag => (
            <Tag key={tag} type={tag} />
          ))}
        </CardActions>
      </Card>
    );
  }
}

export default withStyles(styles)(PostCard);
