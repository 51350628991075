import React from "react";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import { withStyles } from "@material-ui/core/styles";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";

import PostCard from "./post-card";

const styles = () => ({
  gridList: {
    flex: 1
  }
});

const getColumns = width => {
  if (isWidthUp("lg", width)) {
    return 3;
  }
  if (isWidthUp("md", width)) {
    return 2;
  }
  if (isWidthUp("sm", width)) {
    return 2;
  }
  return 1;
};

const PostsList = props => {
  const { classes, posts } = props;
  return (
    <GridList
      className={classes.gridList}
      cellHeight="auto"
      cols={getColumns(props.width)}
      spacing={50}
    >
      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug;
        return (
          <GridListTile key={node.fields.slug} cols={1}>
            <PostCard
              date={node.frontmatter.date}
              image={node.frontmatter.image.childImageSharp.fluid}
              link={node.fields.slug}
              tags={node.frontmatter.tags}
              timeToRead={node.timeToRead}
              title={title}
            />
          </GridListTile>
        );
      })}
    </GridList>
  );
};

const WithWidth = withWidth()(PostsList);

export default withStyles(styles)(WithWidth);
