import React, { useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faPalette,
  faMale,
  faEnvelope
} from "@fortawesome/free-solid-svg-icons";

import Social from "../header/social";

import { COLORS } from "../../constants";

const DEFAULT_ICON_COLOR = COLORS.GRAY.DARK;
const HOVER_ICON_COLOR = COLORS.GRAY.LIGHT;

const NavigationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 6;
  align-items: center;
  padding: 0;
`;

const MenuList = styled.ul`
  padding-left: 0;
  list-style-type: none;
  position: relative;
  text-align: center;
  box-shadow: none;
  background-color: transparent;
  margin-left: 0;
  background: transparent;
  z-index: 10;
  width: 60px;
`;

const MenuItem = styled.li`
  position: relative;
  transition: all 0.35s ease;
  border-radius: 10px;
  background: ${COLORS.GRAY.LIGHT};
  margin: 24px 0;
  text-align: center;
  box-shadow: 0px 0px 2px ${COLORS.BLACK.LIGHT_SHADOW};
  padding: 21px 0;

  &:hover {
    background: ${COLORS.BLUE.DARK};
    color: ${COLORS.GRAY.LIGHT};
  }
`;

const MenuLabel = styled.span`
  position: absolute;
  background: ${COLORS.BLUE.DARK};
  right: ${props => props.large ? '-138px' : '-114px'};
  bottom: 13px;
  font-family: Montserrat;
  font-size: 13px;
  border-radius: 4px;
  transition: visibility 0s linear 300ms, opacity 300ms;
  color: ${COLORS.WHITE};
  padding: 7px 2px;
  text-transform: uppercase;
  font-weight: 700;
  min-width: 100px;
  display: block;
  letter-spacing: 1px;
  opacity: ${props => (props.visible ? 1 : 0)};

  &::after {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 6px;
    margin-top: -6px;
    border-right-color: ${COLORS.BLUE.DARK};
  }
`;

const MenuDesktop = () => {
  const [blogIconColor, setBlogIconColor] = useState(DEFAULT_ICON_COLOR);
  const [aboutIconColor, setAboutIconColor] = useState(DEFAULT_ICON_COLOR);
  const [illustrationsIconColor, setIllustrationsIconColor] = useState(
    DEFAULT_ICON_COLOR
  );
  const [contactIconColor, setContactIconColor] = useState(DEFAULT_ICON_COLOR);
  const [blogLabelOpacity, setBlogLabelOpacity] = useState(0);
  const [illustrationsLabelOpacity, setIllustrationsLabelOpacity] = useState(0);
  const [aboutLabelOpacity, setAboutLabelOpacity] = useState(0);
  const [contactLabelOpacity, setContactLabelOpacity] = useState(0);

  return (
    <NavigationWrapper>
      <MenuList>
        <Link to="/">
          <MenuItem
            onMouseEnter={() => {
              setBlogIconColor(HOVER_ICON_COLOR);
              setBlogLabelOpacity(1);
            }}
            onMouseLeave={() => {
              setBlogIconColor(DEFAULT_ICON_COLOR);
              setBlogLabelOpacity(0);
            }}
          >
            <FontAwesomeIcon icon={faPen} size="lg" color={blogIconColor} />
            <MenuLabel visible={blogLabelOpacity}>Blog</MenuLabel>
          </MenuItem>
        </Link>
        <Link to="/illustrations">
          <MenuItem
            onMouseEnter={() => {
              setIllustrationsIconColor(HOVER_ICON_COLOR);
              setIllustrationsLabelOpacity(1);
            }}
            onMouseLeave={() => {
              setIllustrationsIconColor(DEFAULT_ICON_COLOR);
              setIllustrationsLabelOpacity(0);
            }}
          >
            <FontAwesomeIcon
              icon={faPalette}
              size="lg"
              color={illustrationsIconColor}
            />
            <MenuLabel large visible={illustrationsLabelOpacity}>
              Illustrations
            </MenuLabel>
          </MenuItem>
        </Link>
        <Link to="/about/">
          <MenuItem
            onMouseEnter={() => {
              setAboutIconColor(HOVER_ICON_COLOR);
              setAboutLabelOpacity(1);
            }}
            onMouseLeave={() => {
              setAboutIconColor(DEFAULT_ICON_COLOR);
              setAboutLabelOpacity(0);
            }}
          >
            <FontAwesomeIcon icon={faMale} size="lg" color={aboutIconColor} />
            <MenuLabel visible={aboutLabelOpacity}>About</MenuLabel>
          </MenuItem>
        </Link>
        <Link to="/contact/">
          <MenuItem
            onMouseEnter={() => {
              setContactIconColor(HOVER_ICON_COLOR);
              setContactLabelOpacity(1);
            }}
            onMouseLeave={() => {
              setContactIconColor(DEFAULT_ICON_COLOR);
              setContactLabelOpacity(0);
            }}
          >
            <FontAwesomeIcon
              icon={faEnvelope}
              size="lg"
              color={contactIconColor}
            />
            <MenuLabel visible={contactLabelOpacity}>Contact</MenuLabel>
          </MenuItem>
        </Link>
      </MenuList>
      <Social mode="desktop" />
    </NavigationWrapper>
  );
};

export default MenuDesktop;
