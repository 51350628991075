export default {
  ANGULAR: "#ca2b39",
  BLACK: {
    DARK: "#2d2d2d",
    DARK_SHADOW: "rgba(0, 0, 0, 0.8)",
    LIGHT_SHADOW: "rgba(0, 0, 0, 0.3)"
  },
  BLUE: {
    // DARK: "#3f51b5",
    DARK: "#3245E9",
    LIGHT: "#5f70ce"
  },
  CSS: "#3867e8",
  GRAY: {
    LIGHT: "#efe9e9",
    DARK: "#555"
  },
  GREEN: {
    SUCCESS: "#43a047"
  },
  HTML: "#e16d39",
  JAVASCRIPT: {
    GRAY: "#323330",
    YELLOW: "#f7df1e"
  },
  NODEJS: {
    GRAY: "#333333",
    GREEN: "#026E01"
  },
  PURPLE: '#6441A5',
  REACT: {
    BLUE: "#61dafb",
    GRAY: "#282C33"
  },
  RED: {
    ERROR: "#d32f2f"
  },
  TYPESCRIPT: "#6298d2",
  VUE: "#4fc08d",
  WHITE: "#f2f2f2",
  YELLOW: {
    WARNING: "#ffa000"
  }
};
