import React, { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDev,
  faGithubSquare,
  faLinkedin,
  faTwitch,
  faTwitterSquare
} from "@fortawesome/free-brands-svg-icons";

import { COLORS } from "../../constants";

const DEFAULT_SOCIAL_ICON_COLOR = COLORS.GRAY.LIGHT;
const HOVER_SOCIAL_ICON_COLOR = COLORS.BLUE.DARK;

const SocialWrapper = styled.div`
  flex: 2;
  align-self: center;
  @media (max-width: 1024px) {
    display: flex;
  }
`;

const SocialList = styled.ul`
  display: flex;
  padding-left: 0;
  margin-left: 0;
  margin-top: 20px;
  list-style-type: none;

  @media (max-width: 1024px) {
    display: flex;
    justify-content: center;
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (max-width: 512px) {
    display: flex;
    flex: 0.5;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;

const SocialItemLink = styled.a`
  padding: 0 12px;
`;

const SocialItem = styled.li`
  display: inline-block;

  @media (max-width: 1024px) {
    margin-bottom: 0;
  }
`;

const MobileSocialWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 30px;
  background: ${COLORS.BLACK.DARK};
`;

const Social = props => {
  const [twitterIconColor, setTwitterIconColor] = useState(
    DEFAULT_SOCIAL_ICON_COLOR
  );
  const [gitHubIconColor, setGitHubIconColor] = useState(
    DEFAULT_SOCIAL_ICON_COLOR
  );
  const [linkedInIconColor, setLinkedInIconColor] = useState(
    DEFAULT_SOCIAL_ICON_COLOR
  );
  const [devIconColor, setDevIconColor] = useState(DEFAULT_SOCIAL_ICON_COLOR);
  const [mediumIconColor, setMediumIconColor] = useState(
    DEFAULT_SOCIAL_ICON_COLOR
  );

  const SOCIAL_ITEMS = [
    {
      name: "twitter",
      icon: faTwitterSquare,
      link: "https://twitter.com/xabadu",
      color: {
        default: DEFAULT_SOCIAL_ICON_COLOR
      }
    },
    {
      name: "github",
      icon: faGithubSquare,
      link: "https://github.com/Xabadu",
      color: {
        default: DEFAULT_SOCIAL_ICON_COLOR
      }
    },
    {
      name: "devTo",
      icon: faDev,
      link: "https://dev.to/xabadu",
      color: {
        default: DEFAULT_SOCIAL_ICON_COLOR
      }
    },
    {
      name: "twitch",
      icon: faTwitch,
      link: "https://twitch.tv/xabadu",
      color: {
        default: DEFAULT_SOCIAL_ICON_COLOR
      }
    },
    {
      name: "linkedIn",
      icon: faLinkedin,
      link: "https://www.linkedin.com/in/fernandolarranaga/",
      color: {
        default: DEFAULT_SOCIAL_ICON_COLOR
      }
    }
  ];

  if (props.mode === "mobile") {
    return (
      <MobileSocialWrapper>
        {SOCIAL_ITEMS.map(item => (
          <a href={item.link} key={item.name}>
            <FontAwesomeIcon
              icon={item.icon}
              color={item.color.default}
              size="2x"
            />
          </a>
        ))}
      </MobileSocialWrapper>
    );
  }

  return (
    <SocialWrapper>
      <SocialList>
        <SocialItem>
          <SocialItemLink
            href="https://twitter.com/xabadu"
            onMouseEnter={() => setTwitterIconColor(HOVER_SOCIAL_ICON_COLOR)}
            onMouseLeave={() => setTwitterIconColor(DEFAULT_SOCIAL_ICON_COLOR)}
          >
            <FontAwesomeIcon
              icon={faTwitterSquare}
              color={twitterIconColor}
              size="2x"
            />
          </SocialItemLink>
        </SocialItem>
        <SocialItem>
          <SocialItemLink
            href="https://github.com/Xabadu"
            onMouseEnter={() => setGitHubIconColor(HOVER_SOCIAL_ICON_COLOR)}
            onMouseLeave={() => setGitHubIconColor(DEFAULT_SOCIAL_ICON_COLOR)}
          >
            <FontAwesomeIcon
              icon={faGithubSquare}
              color={gitHubIconColor}
              size="2x"
            />
          </SocialItemLink>
        </SocialItem>
        <SocialItem>
          <SocialItemLink
            href="https://www.linkedin.com/in/fernandolarranaga/"
            onMouseEnter={() => setLinkedInIconColor(HOVER_SOCIAL_ICON_COLOR)}
            onMouseLeave={() => setLinkedInIconColor(DEFAULT_SOCIAL_ICON_COLOR)}
          >
            <FontAwesomeIcon
              icon={faLinkedin}
              color={linkedInIconColor}
              size="2x"
            />
          </SocialItemLink>
        </SocialItem>
        <SocialItem>
          <SocialItemLink
            href="https://dev.to/xabadu"
            onMouseEnter={() => setDevIconColor(HOVER_SOCIAL_ICON_COLOR)}
            onMouseLeave={() => setDevIconColor(DEFAULT_SOCIAL_ICON_COLOR)}
          >
            <FontAwesomeIcon icon={faDev} color={devIconColor} size="2x" />
          </SocialItemLink>
        </SocialItem>
        <SocialItem>
          <SocialItemLink
            href="https://twitch.tv/xabadu"
            onMouseEnter={() => setMediumIconColor(HOVER_SOCIAL_ICON_COLOR)}
            onMouseLeave={() => setMediumIconColor(DEFAULT_SOCIAL_ICON_COLOR)}
          >
            <FontAwesomeIcon
              icon={faTwitch}
              color={mediumIconColor}
              size="2x"
            />
          </SocialItemLink>
        </SocialItem>
      </SocialList>
    </SocialWrapper>
  );
};

export default Social;
