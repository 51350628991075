import React from "react";
import styled from "styled-components";

import { COLORS } from "../../constants";

const AboutWrapper = styled.div`
  flex: 0.1;
  text-align: center;
`;

const AboutHeader = styled.h4`
  font-family: Montserrat;
  font-size: 1rem;
  margin-top: 0.8rem;
  margin-bottom: 0;
  text-transform: uppercase;
  color: ${COLORS.GRAY.LIGHT};

  @media (max-width: 1024px) {
    display: none;
  }
`;

const About = () => (
  <AboutWrapper>
    <AboutHeader>
      Hi, I'm Fernando{" "}
      <span role="img" aria-label="Hi, I'm Fernando">
        ✌️
      </span>
    </AboutHeader>
  </AboutWrapper>
);

export default About;
