import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import { withStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import kebabCase from "lodash/kebabCase";

import { COLORS, TAGS } from "../../constants";

const styles = () => ({
  cssAvatar: {
    border: `1px solid ${COLORS.GRAY.LIGHT}`,
    background: COLORS.CSS
  },
  cssTag: {
    border: `1px solid ${COLORS.GRAY.LIGHT}`,
    color: COLORS.GRAY.DARK
  },
  htmlAvatar: {
    border: `1px solid ${COLORS.GRAY.LIGHT}`,
    background: COLORS.HTML
  },
  htmlTag: {
    border: `1px solid ${COLORS.GRAY.LIGHT}`,
    color: COLORS.GRAY.DARK
  },
  jsAvatar: {
    border: `1px solid ${COLORS.GRAY.LIGHT}`,
    background: COLORS.JAVASCRIPT.YELLOW
  },
  jsTag: {
    border: `1px solid ${COLORS.GRAY.LIGHT}`,
    color: COLORS.JAVASCRIPT.GRAY
  },
  nodeAvatar: {
    background: COLORS.NODEJS.GREEN
  },
  nodeTag: {
    border: `1px solid ${COLORS.GRAY.LIGHT}`,
    color: COLORS.NODEJS.GRAY
  },
  reactAvatar: {
    background: COLORS.REACT.BLUE
  },
  reactTag: {
    border: `1px solid ${COLORS.GRAY.LIGHT}`,
    color: COLORS.REACT.GRAY
  },
  reactnativeAvatar: {
    background: COLORS.BLACK.DARK
  },
  reactnativeTag: {
    border: `1px solid ${COLORS.GRAY.LIGHT}`,
    color: COLORS.REACT.GRAY
  },
  tagContainer: {
    marginBottom: 5,
    marginRight: 5
  }
});

const Tag = props => {
  const { classes, mode, type } = props;
  const avatarClass = classes[`${type}Avatar`];
  const tagClass = classes[`${type}Tag`];
  return (
    <Link to={`/tags/${kebabCase(type)}`} className="button-link">
      {mode === "compact" ? (
        <Avatar className={avatarClass}>
          <FontAwesomeIcon
            icon={TAGS[type].icon.name}
            color={TAGS[type].icon.color}
          />
        </Avatar>
      ) : (
        <Chip
          clickable
          className={classNames(classes.tagContainer, tagClass)}
          avatar={
            <Avatar className={avatarClass}>
              <FontAwesomeIcon
                icon={TAGS[type].icon.name}
                color={TAGS[type].icon.color}
              />
            </Avatar>
          }
          label={TAGS[type].label}
          variant="outlined"
        />
      )}
    </Link>
  );
};

Tag.defaultProps = {
  mode: "full"
};

Tag.propTypes = {
  mode: PropTypes.string,
  type: PropTypes.string.isRequired
};

export default withStyles(styles)(Tag);
