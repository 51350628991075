import React, { Component } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import styled from "styled-components";
import {
  Facebook,
  HackerNews,
  Linkedin,
  Mail,
  Reddit,
  Twitter
} from "react-social-sharing";

const ShareWrapper = styled.div`
  display: flex;
  margin-top: 1rem;
  justify-content: center;
`;

class ShareWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      link: null
    };
  }

  componentDidMount() {
    this.setState({ link: window.location.href });
  }

  parseUrl(host, slug) {
    if (host !== null) {
      return `${host.slice(0, -1)}${slug}`;
    }
  }

  render() {
    const { anchorEl, handleClose, mode, slug, title } = this.props;
    const { link } = this.state;

    if (mode === "compact") {
      return (
        <div>
          <ShareWrapper>
            <Twitter solid small message={title} link={link} />
            <Facebook solid small link={link} />
            <Linkedin solid small message={title} link={link} />
          </ShareWrapper>
          <ShareWrapper>
            <Mail solid small subject={title} link={link} />
            <Reddit solid small link={link} />
            <HackerNews solid small message={title} link={link} />
          </ShareWrapper>
        </div>
      );
    }

    return (
      <Menu
        id="sharing-widget-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          <Twitter message={title} link={`${this.parseUrl(link, slug)}`} />
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Facebook link={`${this.parseUrl(link, slug)}`} />
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Linkedin message={title} link={`${this.parseUrl(link, slug)}`} />
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Mail subject={title} link={`${this.parseUrl(link, slug)}`} />
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Reddit link={`${this.parseUrl(link, slug)}`} />
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <HackerNews message={title} link={`${this.parseUrl(link, slug)}`} />
        </MenuItem>
      </Menu>
    );
  }
}

export default ShareWidget;
