import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import styled from "styled-components";

import Layout from "../components/layout";
import SEO from "../components/seo";
import PostsList from "../components/blog/posts-list";
import Tag from "../components/blog/tag";

const TagsWrapper = styled.div`
  display: flex;
  font-family: Montserrat, Roboto, "Helvetica Neue";
  height: 100%;
  overflow: scroll;
  padding: 5em;
  flex-direction: column;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const TitleText = styled.h1`
  margin-right: 0.5rem;
`;

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext;
  const { edges, totalCount } = data.allMarkdownRemark;
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? "" : "s"
  } tagged with`;

  return (
    <Layout>
      <SEO title="Xabadu" keywords={[`gatsby`, `application`, `react`]} />
      <TagsWrapper>
        <TitleWrapper>
          <TitleText>{tagHeader}</TitleText>
          <Tag mode="compact" type={tag} />
        </TitleWrapper>
        <PostsList posts={edges} />
        <Link to="/tags" className="regular-links">
          All Tags
        </Link>
      </TagsWrapper>
    </Layout>
  );
};

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired
            })
          }).isRequired
        })
      )
    })
  })
};

export default Tags;

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          timeToRead
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            tags
            description
            image {
              childImageSharp {
                resize(width: 560, height: 315) {
                  src
                }
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
