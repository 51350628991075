import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import xabadu from "../../images/xabadu-transparent-sq.png";

const LogoWrapper = styled.div`
  flex: 0.5;
  margin-top: 3rem;

  @media (max-width: 1024px) {
    margin-top: 0;
    flex: 1;
  }
`;

const LogoImage = styled.div`
  background-image: url(${xabadu});
  background-size: contain;
  width: 150px;
  height: 150px;

  @media (max-width: 1024px) {
    width: 100px;
    height: 100px;
  }

  @media (max-width: 512px) {
    width: 75px;
    height: 75px;
  }
`;

const Logo = () => (
  <LogoWrapper>
    <Link to="/">
      <LogoImage />
    </Link>
  </LogoWrapper>
);

export default Logo;
