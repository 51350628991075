import COLORS from "./colors";
import {
  faCss3Alt,
  faHtml5,
  faJs,
  faNode,
  faReact
} from "@fortawesome/free-brands-svg-icons";

export default {
  css: {
    icon: {
      color: COLORS.WHITE,
      name: faCss3Alt
    },
    label: "CSS"
  },
  html: {
    icon: {
      color: COLORS.GRAY.LIGHT,
      name: faHtml5
    },
    label: "HTML"
  },
  js: {
    icon: {
      color: COLORS.JAVASCRIPT.GRAY,
      name: faJs
    },
    label: "JavaScript"
  },
  node: {
    icon: {
      color: "white",
      name: faNode
    },
    label: "Node.js"
  },
  react: {
    icon: {
      color: COLORS.REACT.GRAY,
      name: faReact
    },
    label: "React"
  },
  reactnative: {
    icon: {
      color: COLORS.WHITE,
      name: faReact
    },
    label: "React Native"
  }
};
